import React, { createRef, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import NavContainer from '../components/NavContainer'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Panel from '../components/Panel'
import {
  featuredImageProps,
  frontmatterProps,
  fieldsProps,
  siteProps,
  heroProps,
} from '../proptypes'
import { removeTrailingSlash } from '../utils'

const panelMap = [
  'Banner',
  'BlogRoll',
  'Columns',
  'Contact',
  'Divider',
  'FullWidth',
  'ImageDescription',
  'ImgTxtRow',
]

export const PageTemplate = ({ hero, featuredImage, slug, panels, subnav }) => {
  const pageClasses = [
    'content',
    'content-page',
    `page-${
      slug === '/'
        ? 'home'
        : slug
            .split('/')
            .filter((i) => i)
            .join('-')
    }`,
    subnav && subnav.length ? 'has-subnav' : '',
  ]
    .filter((i) => i)
    .join(' ')
  const [panelRefs] = useState(panels.map(() => createRef()))
  const heroRef = useRef()

  return (
    <div id="content" className={pageClasses}>
      <NavContainer subnav={subnav} targetRefs={[heroRef, ...panelRefs]} />
      <Hero {...hero} featuredImage={featuredImage} ref={heroRef} />
      <div className="main-wrapper">
        <main id="main" className="main">
          {!!panels &&
            panels.length &&
            panels.map((props, i) => {
              return <Panel key={uuidv4()} ref={panelRefs[i]} {...props} />
            })}
        </main>
      </div>
    </div>
  )
}

PageTemplate.propTypes = {
  hero: PropTypes.shape(heroProps),
  featuredImage: featuredImageProps,
  slug: PropTypes.string.isRequired,
  panels: PropTypes.array,
  subnav: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
}

export const Page = ({ data }) => {
  const { hero, featuredImage, panels } = data.markdownRemark.frontmatter
  const { inlineImages, slug } = data.markdownRemark.fields
  const { location } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  const pageProps = {
    hero,
    featuredImage,
    slug,
    panels: panels
      .filter(({ type }) => panelMap.includes(type))
      .map((panel, refIndex) => ({
        ...panel,
        refIndex,
        inlineImages,
      })), // need to track order
  }
  pageProps.subnav = pageProps.panels
    .filter((panel) => panel.subnavLabel && panel.subnavAnchor)
    .map(({ subnavLabel, subnavAnchor, refIndex }) => ({
      link: subnavAnchor,
      label: subnavLabel,
      refIndex,
    }))
  return (
    <Layout location={location} data={data}>
      <PageTemplate {...pageProps} />
    </Layout>
  )
}

Page.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    site: siteProps.isRequired,
    markdownRemark: PropTypes.shape({
      fields: fieldsProps.isRequired,
      frontmatter: frontmatterProps.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageTemplate($id: String!) {
    site {
      ...siteMeta
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
        inlineImages {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      frontmatter {
        ...seoFields
        ...heroFields
        ...featuredImageFields
        template
        panels {
          type
          align
          subnavLabel
          subnavAnchor
          title
          subtitle_MD
          content_MD
          colImg {
            colWidth
            alignment
            src {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            classes
          }
          rowMeta {
            colWidth
            alignment
            alternate
          }
          rows {
            img {
              src {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
            icon {
              src {
                publicURL
              }
              alt
            }
            txt_MD
          }
          padding {
            top
            bottom
            left
            right
          }
          columns {
            align
            txtAbove
            img {
              src {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
            }
            icon {
              src {
                publicURL
              }
              alt
            }
            iconImg
            txt_MD
          }
        }
      }
    }
  }
`
