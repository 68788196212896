import React, {
  createElement,
  forwardRef,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react'
import { useInView } from 'react-intersection-observer'
import { panelProps } from '../../proptypes'
import loadable from '@loadable/component'
import HTMLContent from '../HTMLContent'

import './index.sass'

const panelMap = {
  FullWidth: loadable(() => import('./FullWidth')),
  ImageDescription: loadable(() => import('./ImageDescription')),
  ImgTxtRow: loadable(() => import('./ImgTxtRow')),
  Divider: loadable(() => import('./Divider')),
  Columns: loadable(() => import('./Columns')),
  Banner: loadable(() => import('./Banner')),
  Contact: loadable(() => import('./Contact')),
  BlogRoll: loadable(() => import('./BlogRoll')),
}

const Panel = forwardRef((props, ref) => {
  const {
    type,
    align,
    textColor,
    title,
    subtitle_MD,
    extraClasses,
    padding,
    subnavAnchor,
    inlineImages,
  } = props
  const initialViewRef = useRef(false)
  const [initialView, setInitialView] = useState(false)
  const [inViewRef, inView] = useInView({ rootMargin: '-50px' })

  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef, ref],
  )

  useLayoutEffect(() => {
    if (inView && !initialViewRef.current) {
      initialViewRef.current = true
      setInitialView(true)
      // console.log(initialViewRef.current)
    }
  }, [inView])

  const extraWrapperClasses =
    extraClasses && extraClasses.length ? extraClasses : []
  const wrapperClasses = [
    'panel',
    `panel${type
      .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
      .toLowerCase()}`,
    textColor ? `text-${textColor}` : '',
    align ? `align${align}` : '',
    initialView ? 'rendered' : '',
    inView ? 'in-view' : '',
    ...extraWrapperClasses,
  ]
    .filter((i) => i)
    .join(' ')

  const styles = {}
  if (padding && Object.keys(padding).length) {
    Object.keys(padding).forEach((key) => {
      const Key = key[0].toUpperCase() + key.substring(1)
      styles[`padding${Key}`] = padding[key]
    })
  }

  return (
    <section
      id={subnavAnchor}
      className={wrapperClasses}
      ref={setRefs}
      style={styles}
    >
      {(!!title || !!subtitle_MD) && (
        <div className="panel-header">
          {!!title && (
            <div className="panel-title">
              <h2>{title}</h2>
            </div>
          )}
          {!!subtitle_MD && (
            <HTMLContent
              className={`panel-subtitle`}
              content={subtitle_MD}
              inlineImages={inlineImages}
            />
          )}
        </div>
      )}
      <div className="panel-content">
        {createElement(panelMap[type], {
          ...props,
        })}
      </div>
    </section>
  )
})

Panel.propTypes = panelProps

export default Panel
